<template>
  <a-card :body-style="{ padding: '24px 32px' }" :bordered="false">
    <a-form @submit="handleSubmit" :form="form">
      <a-form-item label="父级节点" :labelCol="labelCol" :wrapperCol="wrapperCol">
        <a-tree-select style="width: 100%" :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
          :tree-data="treeData" v-decorator="[
            'pid',
            {
              rules: [{ required: true, message: '需选择父级节点' }]
            }
          ]" placeholder="Please select" tree-default-expand-all>
        </a-tree-select>
      </a-form-item>
      <a-form-item label="名称" :labelCol="labelCol" :wrapperCol="wrapperCol">
        <a-input v-decorator="[
          'name',
          {
            rules: [{ required: true, message: '节点名称不能为空' }]
          },
        ]" placeholder="节点名称" />
      </a-form-item>
      <a-form-item :wrapperCol="{ span: 24 }" style="text-align: center">
        <a-button htmlType="submit" :loading="submitLoading" type="primary">提交</a-button>
        <a-button style="margin-left: 8px" @click="routerback">返回</a-button>
      </a-form-item>
    </a-form>
  </a-card>
</template>

<script>
// import { nodeAdd } from '@/api/admin'
import { TreeSelect as ATreeSelect } from 'ant-design-vue'

export default {
  name: 'HelpCatesAdd',
  components: {
    ATreeSelect
  },
  props: {
    pid: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      description: '新增路由节点',
      treeData: [],
      labelCol: {
        lg: { span: 7 },
        sm: { span: 7 }
      },
      wrapperCol: {
        lg: { span: 10 },
        sm: { span: 17 }
      },

      // form
      form: this.$form.createForm(this),
      submitLoading: false
    }
  },
  created() {
    this.loadData()
  },
  methods: {
    loadData() {
      this.tableLoading = true
      this.$http({
        url: '/admin/helps/cates_list'
      }).then(res => {
        if (res.status === 'success') {
          const data = res.data
          console.log(data)
          const filterData = []
          data.forEach((value, index) => {
            const obj = {}
            obj.id = value.id
            obj.pid = value.pid
            obj.key = value.id
            obj.title = value.name
            obj.value = value.id
            filterData[index] = obj
          })
          const handleData = this.getChild(filterData, 0)

          handleData.unshift({ id: 0, key: 0, pid: 0, title: '作为顶级节点', value: 0 })
          this.treeData = handleData
          this.form.setFieldsValue({
            pid: this.pid
          })
        } else {
          this.$notification.error({
            message: res.status,
            description: res.message
          })
        }
      }).finally(() => {
        this.tableLoading = false
      })
    },
    // handler
    handleSubmit(e) {
      e.preventDefault()
      this.form.validateFields((err, values) => {
        if (!err) {
          // 提交数据
          this.$http({
            url: '/admin/helps/cates_add',
            method: 'post',
            data: values
          }).then(res => {
            if (res.status === 'success') {
              this.$notification.success({
                message: res.status,
                description: res.message
              })
              // 返回上一页
              this.$router.back(-1)
            } else {
              this.$notification.error({
                message: res.status,
                description: res.message
              })
            }
          })
        }
      })
    },
    routerback: function () {
      this.$router.back(-1)
    },
    getChild(data, id) {
      const child = []
      var i = 0
      data.forEach((value, index) => {
        if (value.pid === id) {
          child[i] = value
          const children = this.getChild(data, value.id)
          if (children.length > 0) {
            child[i].children = children
          }
          i = i + 1
        }
      })
      return child
    }
  },
  watch: {
    value(value) {
      console.log(value)
    }
  }
}
</script>
